import React from 'react'


const About = () => {
  return (
    
    <div className='box mt-5 p-3 mx-auto'>

      <h1>Contact Us</h1>
      <hr />
      <div className='text-center'>
        <p className='m-0'><span className='navbarLogo'>BARBONE <span className='it'>IT</span> SOLUTIONS, LLC</span></p>
        <p className='m-0'>PO Box 730862</p>
        <p className='m-0'>Ormond Beach, FL 32173-0862</p>

        <p className='m-0'>
          <a href='mailto:joe@barboneitsolutions.com'> Joe@BarboneITSolutions.com</a>
        </p>

        <p>(386) 341-9040</p>
      </div>
    </div>
  )
}

export default About