import React from 'react'

const Footer = () => {
  return (
    <div className='footer'>
        <hr />
        <p className='m-0'>&copy;2023 Barbone <span className='it'>IT</span> Solutions, LLC</p>
        <p className='m-0'>All rights reserved.</p>
    </div>
  )
}

export default Footer