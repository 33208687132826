import React from 'react'
import 'bootstrap/dist/css/bootstrap.css'; 
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image'
import NavDropdown from 'react-bootstrap/NavDropdown';

import bitsLogo from '../images/bitslogo_lr.png'

const Navigation = () => {
  return (
    <Navbar expand="lg" className="bg-body-tertiary" sticky='top'>
      <Container fluid>
        {/* <Navbar.Brand href="/"><span className='navbarLogo'>BARBONE <span className='it'>IT</span> SOLUTIONS</span></Navbar.Brand> */}
        <Navbar.Brand href="/"><Image className='navbarLogo' src={bitsLogo} alt='bits logo' /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            
            {/* <Nav.Link href="#home">Home</Nav.Link> */}
            
            
            
            <NavDropdown title="Web Apps" id="basic-nav-dropdown">
              {/* <NavDropdown.Item href="/">My Auto Garage</NavDropdown.Item> */}
              <NavDropdown.Item href="https://mymotogarage.app" target='_blank'>My Moto Garage</NavDropdown.Item>
              <NavDropdown.Item href="http://mw.joebarbone.com" target='_blank'>My Wallet</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Gift Card Valet</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Support Ticket System</NavDropdown.Item>

              <NavDropdown.Divider />
              
              <NavDropdown.Item href="#action/3.4">
                {/* Something cool here? */}
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link href="/about">About</Nav.Link>
            <Nav.Link href="/contact">Contact</Nav.Link>
          
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Navigation