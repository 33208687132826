import "./index.css"
import Nav from "./pages/Navigation";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact"
import Footer from "./pages/Footer";
import ITSupport from "./pages/ITSupport"
import AppDev from "./pages/AppDev"
import Shopify from "./pages/Shopify"
import Nopage from "./pages/Nopage";


import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div>
      
      <Router>
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/itsupport" element={<ITSupport />} />
          <Route path="/appdev" element={<AppDev />} />
          <Route path="/shopify" element={<Shopify />} />
          <Route path="*" element={<Nopage />} />
        </Routes>
        <Footer />
      </Router>
    
    </div>
  );
}

export default App;
