import React from 'react'
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Nav from "./Navigation"
import Image from "react-bootstrap/Image"
import headerImg from "../images/headerImg.jpg"

const Home = () => {
  return (
    
    <Container fluid>
        <Row>
            <Image fluid className='m-0 p-0' src={headerImg} />

            <div className="welcome">
                <p className='welcomeLeft'>Looking for IT solutions?</p>
                <p className='welcomeRight'>Look no further.</p>
            </div>
        </Row>

        <div className="row products mt-5 mb-5 text-center d-flex justify-content-around">
            
            <div className="box col-sm-12 col-lg-3">
                <h2 className="title">IT SUPPORT</h2>
                <hr />
                <p className="message">
                    Let us handle all of your IT needs. With over 30 years of experience, we can provide the solutions you need so you can focus
                    on running your business and not learning about ever changing technology. We support everything from Microsoft desktop and server OS to Google ChromeOS
                    and Google ChromeOS Flex.
                </p>
                
                <div className="message">
                    {/* <a href='/itsupport'>Learn more...</a> */}
                </div>
            </div>
            
            <div className="box col-sm-12 col-lg-3">
                <h2 className="title">APP DEVELOPMENT</h2>
                <hr />
                <p className="message">
                    The heart of Barbone IT Solutions is application development. Have a business need but don't want software to dictate how you run your business? Let us create a
                    custom web application so you can run your business the way YOU want to.
                </p>
                <div className="message">
                    {/* <a href='/appdev'>Learn more...</a> */}
                </div>
            </div>
            
            <div className="box col-sm-12 col-lg-3">
                <h2 className="title">SHOPIFY SUPPORT</h2>
                <hr />
                <p className="message">
                Looking to sell your products online but are overwhelmed trying to setup your Shopify store? We are here to help. We currently support several Shopify stores now
                 and would be happy to get you up and running.
                </p>
                <div className="message">
                    {/* <a href='/shopify'>Learn more...</a> */}
                </div>
            </div>            
            
        </div>
    </Container>
    
  )

}


export default Home