import React from 'react'


const About = () => {
  return (
    
    <div className='box mt-5 p-3 mx-auto'>

      <h1>BITS and Pieces</h1>
      <hr />
      <p>
        Thank you for stopping by Barbone IT Solutions. Our mission is to provide IT solutions to small businesses that allow them to focus on taking care of their customer
        and not dedicating time and resources to keeping up with technology trends. And with over 30 years of IT experience, we can do just that.
      </p>

      <p>
        Spend your time working with and supporting your customers, not worrying about your IT environment. We can support a wide variety of hardware and software platforms
        ranging from Microsoft Windows desktop and server operating systems to Google ChromeOS and ChromeOS Flex. We can also work with your service providers to ensure the 
        solutions you employee are setup and configured according to vendor best practices.
      </p>

      <p>
        We can also create custom applications to help you run your business. From simple CRUD apps (Create, Read, Update, Delete) to more complex job cost systems, we can 
        deliver the products and services you need to help you grow and support you when you do.
      </p>

      <p>
        Reach out today to see how we can help turn your dreams into reality.
      </p>

      <p>
        <a href='mailto:joe@barboneitsolutions.com'> Joe@BarboneITSolutions.com</a>
      </p>
    </div>
  )
}

export default About